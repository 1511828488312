import env from "~/env";

/**
 * True if the current installation is the cloud hosted version at getoutline.com
 */
const isCloudHosted = [
  "https://knowbase.piscale.com",
  "https://knowbase.deepbazan.com",
].includes(env.URL);

export default isCloudHosted;
